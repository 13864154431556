<template>
  <v-container
    id="user-list-container"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          class="white--text btn-center"
          @click="markAllAsRead()"
        >
          Mark all as read
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      -<v-col cols="12">
        <v-data-table
          :headers="config.headers"
          :items="notifications"
          :items-per-page="config.pagination.size"
          :loading="loading"
          :disable="loading"
          hide-default-footer
          :disable-sort="true"
          loading-text="Loading... Please wait"
          class="elevation-1 row-pointer"
          @click:row="clickRow"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col
        cols="11"
        class="text-right"
      >
        <v-pagination
          v-model="config.pagination.page"
          :length="config.pagination.totalPages"
          @input="fetchNotifications()"
        />
      </v-col>
      <v-col
        cols="1"
        class="text-right"
        align-self="end"
      >
        <v-select
          v-model="config.pagination.size"
          class="pa-0 ma-0 mt-2"
          :items="[5, 10, 25, 50, 100]"
          @change="changePageSize()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import notificationService from '@/shared/notifications'
import { mapState } from 'vuex'
import DateTimeService from '@/service/DateTimeService'

export default {
  name: 'UsersList',
  data: () => ({
    config: {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Module', value: 'category' },
        { text: 'Message', value: 'message' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Is Read ?', value: 'isReadString' }
      ],
      pagination: {
        page: 1,
        size: 25,
        totalPages: 1
      },
      search: {
        criteria: ''
      }
    },
    loading: false,
    notifications: []
  }),
  getters: {
    ...mapState(['user'])
  },
  beforeMount () {
    this.fetchNotifications()
  },
  methods: {
    async changePageSize () {
      this.config.pagination.page = 1
      await this.fetchNotifications()
    },
    async clickRow (n) {
      await this.$router.push(n.clickLink)
      if (!n.isRead) {
        await notificationService.markRead(n.id)
      }
    },
    async markAllAsRead () {
      this.loading = true
      const response = await notificationService.markAllAsRead(this.$store.getters.user.accessId)
      if (response.status === 200) {
        await this.fetchNotifications()
      }
      this.loading = false
    },
    async fetchNotifications () {
      // state
      this.loading = true
      this.notifications = []
      const response = await notificationService.findAll(
        { page: this.config.pagination.page - 1, size: this.config.pagination.size },
        { criteria: this.config.search.criteria }
      )
      if (response.status === 200) {
        this.notifications = response.data.content
        this.notifications = this.notifications.map(i => {
          i.isReadString = i.isRead ? 'READ' : 'UNREAD'
          i.createdAt = DateTimeService.format(new Date(i.createdAt))
          return i
        })
        this.config.pagination.totalPages = response.data.totalPages
        // eslint-disable-next-line no-return-assign
        // this.notifications.forEach(u => u.lockedString = u.locked ? 'Locked' : 'Unlocked')
      }
      this.loading = false
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}

.full-height {
  height: 100%;
}

.row-pointer :hover {
  cursor: pointer;
}

</style>
