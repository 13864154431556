import http from './http'
import apiResultWrapper from './apiResultWrapper'
// find pageable notifications
const findAll = async ({ page, size }, { criteria }) => apiResultWrapper.apiResult(await http.get(`/api/v1/notifications?page=${page || 0}&size=${size || 25}${criteria ? '&criteria=' + criteria : ''}`))
// find pageable notifications
const findAllUnread = async ({ page, size }, { criteria }) => apiResultWrapper.apiResult(await http.get(`/api/v1/notifications/unread?page=${page || 0}&size=${size || 25}${criteria ? '&criteria=' + criteria : ''}`))
// get one notification
const findById = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/notifications/${id}`))
// mark as read
const markRead = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/notifications/${id}/read`))
// mark as unread
const markUnread = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/notifications/${id}/unread`))
// mark all as read
const markAllAsRead = async (ownerId) => apiResultWrapper.apiResult(await http.post(`/api/v1/notifications/read/for-owner/${ownerId}`))

export default {
  findAll,
  findById,
  findAllUnread,
  markRead,
  markUnread,
  markAllAsRead
}
