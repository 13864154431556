
const format = (date) => {
  if (!(date instanceof Date)) {
    console.warn('You try to format non date object', date)
  } else {
    return date.toLocaleString('en')
  }
}

function getDateAsYYYY_MM_DD(date) {
  return new Date(date).toISOString().replace(/T.*$/, ' ').trim()
}

export default {
  format,
  getDateAsYYYY_MM_DD
}
